import firebase from "firebase";
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBY_lPPY_TQfBnFgEYpsiS0BljDg0wcvsk",
  authDomain: "nectar-lists.firebaseapp.com",
  databaseURL: "https://nectar-lists.firebaseio.com",
  projectId: "nectar-lists",
  storageBucket: "nectar-lists.appspot.com",
  messagingSenderId: "384585754060",
  appId: "1:384585754060:web:68f9e68ed2db98827ba969",
  measurementId: "G-JW3QY9K74Q",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;
