import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getItems, toggleItem, deleteItem } from "../data/actions/items";

const ListItems = ({ items: { items }, getItems, toggleItem, deleteItem }) => {
  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <ul className="items-list">
      {items.map((item) => (
        <li key={item._id}>
          <span
            className={item.done ? "done" : ""}
            onClick={() => toggleItem(item)}
          >
            <strong>
              {item.title}
              {item.description ? ": " : ""}
            </strong>
            {item.description}
          </span>
          <button onClick={() => deleteItem(item._id)}>Delete</button>
        </li>
      ))}
    </ul>
  );
};

ListItems.propTypes = {
  items: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  toggleItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

export default connect(mapStateToProps, { getItems, toggleItem, deleteItem })(
  ListItems
);
