import { USER_LOADED, AUTH_ERROR, LOGOUT } from "../actions/types";
import setAuthToken from "../../utils/setAuthToken";
import firebase from "../../firebase";

const initialState = {
  token: null,
  user: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      let token = payload.token;
      console.log(token);
      localStorage.setItem("token", token);
      localStorage.setItem("user", payload.user);
      setAuthToken(token);
      return { ...state, token, user: payload.user, loading: false };
    case AUTH_ERROR:
    case LOGOUT:
      return { ...state, token: null, user: null, loading: false };
    default:
      return state;
  }
}
