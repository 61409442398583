import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreateItem from "./CreateItem";
import ListItems from "./ListItems";
import api from "../utils/api.instance";
import Spinner from "../reaction-time/components/Spinner";

const Content = ({ auth: { user, loading } }) => {
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const ping = async () => {
      try {
        const res = await api.get("auth");
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    console.log(user);
    ping();
  }, [user]);

  return (
    <main className="content">
      {loading ? (
        <Spinner />
      ) : user ? (
        <Fragment>
          <div className="user-profile">
            <img src={user.photoURL} />
          </div>
          <CreateItem />
          <ListItems />
          {/* <Switch>
          <Route exact path="/" component={Landing} />
          <Route component={Routes} />
        </Switch> */}
        </Fragment>
      ) : (
        <div className="wrapper">
          <p>You must be logged in to see the list and submit to it.</p>
        </div>
      )}
    </main>
  );
};

Content.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Content);
