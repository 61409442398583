import { combineReducers } from "redux";
import auth from "./auth";
import items from "./items";
import lists from "./lists";
import alert from "./alert";

export default combineReducers({
  auth,
  items,
  lists,
  alert,
});
