export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";

export const GET_ITEMS = "GET_ITEMS";
export const ITEM_CREATED = "ITEM_CREATED";
export const ITEM_UPDATED = "ITEM_UPDATED";
export const ITEM_DELETED = "ITEM_DELETED";
export const CLEAR_ITEMS = "CLEAR_ITEMS";

export const GET_LISTS = "GET_LISTS";
export const LIST_CREATED = "LIST_CREATED";
export const LIST_UPDATED = "LIST_UPDATED";
export const LIST_DELETED = "LIST_DELETED";
export const CLEAR_LISTS = "CLEAR_LISTS";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
