import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createItem } from "../data/actions/items";

const CreateItem = ({ createItem }) => {
  const initialData = {
    title: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    createItem(formData);
    setFormData(initialData);
  };

  return (
    <form className="create-item" onSubmit={onSubmit}>
      <input
        type="text"
        name="title"
        placeholder="Title"
        value={formData.title}
        onChange={handleChange}
      />{" "}
      <input
        type="text"
        name="description"
        placeholder="Description"
        value={formData.description}
        onChange={handleChange}
      />
      <input type="submit" value="Create!" />
    </form>
  );
};

CreateItem.propTypes = {
  createItem: PropTypes.func.isRequired,
};

export default connect(null, { createItem })(CreateItem);
