exports.getCurrentTheme = function () {
  console.log("Getting theme");
  var b = document.getElementsByTagName("body");
  try {
    var theme = b[0].className.split("--")[1];
    console.log(theme);
    return theme;
  } catch (e) {
    console.error(e);
  }
};

exports.setTheme = function (theme) {
  var b = document.getElementsByTagName("body");
  try {
    b[0].className = "theme--" + theme;
  } catch (e) {
    console.error(e);
  }
};
