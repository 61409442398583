import {
  GET_ITEMS,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_DELETED,
  CLEAR_ITEMS,
} from "../actions/types";

const initialState = {
  items: [],
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ITEMS:
      return { ...state, items: payload, loading: false };
    case ITEM_CREATED:
      return { ...state, items: [...state.items, payload], loading: false };
    case ITEM_UPDATED:
      return {
        ...state,
        items: state.items.map((i) => (i._id === payload._id ? payload : i)),
        loading: false,
      };
    case ITEM_DELETED:
      return {
        ...state,
        items: state.items.filter((i) => i._id !== payload),
        loading: false,
      };
    case CLEAR_ITEMS:
      return { ...state, items: [], loading: false };
    default:
      return state;
  }
}
