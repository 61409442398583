import api from "../../utils/api.instance";
import { setAlert } from "./alert";
import {
  GET_ITEMS,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_DELETED,
  CLEAR_ITEMS,
} from "../actions/types";

export const getItems = () => async (dispatch) => {
  console.log("Getting items");
  try {
    const res = await api.get("items");
    dispatch({
      type: GET_ITEMS,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error");
    const { error, errors } = err;
    if (errors) {
      errors.forEach((e) => {
        dispatch(setAlert(e));
      });
    } else if (error) {
      dispatch(setAlert(error));
    } else {
      dispatch(setAlert(err));
    }
  }
};

export const createItem = (formData) => async (dispatch) => {
  //TODO add the item with a temporary ID and delete it if the api call fails
  try {
    console.log("Create item");
    const res = await api.post("items", formData);
    dispatch({
      type: ITEM_CREATED,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    console.log(err.data);
    console.log(err.errors);
    const { error, errors } = err;
    if (errors) {
      errors.forEach((e) => {
        dispatch(setAlert(e));
      });
    } else if (error) {
      dispatch(setAlert(error));
    } else {
      dispatch(setAlert(err));
    }
  }
};

export const toggleItem = (item) => async (dispatch) => {
  console.log("Toggle Item");
  console.log(item);
  try {
    dispatch({
      type: ITEM_UPDATED,
      payload: { ...item, done: !item.done },
    });
    await api.put(`items/${item._id}`, { done: !item.done });
    // TODO check if the returned item is correct?
  } catch (err) {
    const { error, errors } = err;
    if (errors) {
      errors.forEach((e) => {
        dispatch(setAlert(e));
      });
    } else if (error) {
      dispatch(setAlert(error));
    } else {
      dispatch(setAlert(err));
    }
    // If it went wrong, the list is incorrect
    dispatch({
      type: ITEM_UPDATED,
      payload: { item },
    });
  }
};

export const updateItem = (item_id, formData) => async (dispatch) => {
  //TODO update the item with a temporary ID and undo it if the api call fails
  console.log("Update item");
  console.log(formData);
  try {
    const res = await api.put(`items/${item_id}`, formData);
    dispatch({
      type: ITEM_UPDATED,
      payload: res.data,
    });
  } catch (err) {
    const { error, errors } = err;
    if (errors) {
      errors.forEach((e) => {
        dispatch(setAlert(e));
      });
    } else if (error) {
      dispatch(setAlert(error));
    } else {
      dispatch(setAlert(err));
    }
    // If it went wrong, the list is incorrect
    dispatch(getItems());
  }
};

export const deleteItem = (item_id) => async (dispatch) => {
  console.log("Delete item");
  try {
    dispatch({
      type: ITEM_DELETED,
      payload: item_id,
    });
    await api.delete(`items/${item_id}`);
  } catch (err) {
    const { error, errors } = err;
    if (errors) {
      errors.forEach((e) => {
        dispatch(setAlert(e));
      });
    } else if (error) {
      dispatch(setAlert(error));
    } else {
      dispatch(setAlert(err));
    }
    // If it went wrong, the list is incorrect
    dispatch(getItems());
  }
};
