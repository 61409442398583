import { setAlert } from "./alert";
import { USER_LOADED, AUTH_ERROR, LOGOUT } from "./types";
import firebase, { auth, provider } from "../../firebase";

export const loadUser = () => async (dispatch) => {
  let token = "";
  let user = {};
  // try {
  //   user = firebase.auth().currentUser;
  //   console.log(user);
  //   token = await firebase
  //     .auth()
  //     .currentUser.getIdToken(/* forceRefresh */ true);
  //   console.log(token);
  //   if (user && token) {
  //     dispatch({ type: USER_LOADED, payload: { user, token } });
  //   } else {
  //     dispatch({ type: AUTH_ERROR });
  //   }
  // } catch (e) {
  //   dispatch({ type: AUTH_ERROR });
  //   console.log(e);
  // }

  firebase.auth().onAuthStateChanged(async function (user) {
    if (user) {
      token = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);
      console.log(token);

      dispatch({ type: USER_LOADED, payload: { user, token } });
    } else {
      dispatch({ type: AUTH_ERROR });
    }
  });
};

export const login = () => async (dispatch) => {
  try {
    const res = await auth.signInWithPopup(provider);
    console.log(res);
    if (res.user) dispatch({ type: USER_LOADED, payload: res.user });
    else dispatch(setAlert("Something went wrong", "danger"));
  } catch (error) {
    dispatch(setAlert(error, "danger"));
  }
};

export const logout = () => async (dispatch) => {
  await auth.signOut();
  dispatch({ type: LOGOUT });
};
