import React, { useState, useEffect, Fragment } from "react";
import "./css/index.css";
// Redux
import { Provider } from "react-redux"; // Wrap everything in this so all components have access to the store
import store from "./data/store";
import Navbar from "./components/layout/Navbar";
import CreateItem from "./components/CreateItem";
import { loadUser } from "./data/actions/auth";
import ListItems from "./components/ListItems";
import Content from "./components/Content";

const App = () => {
  //   useEffect(() => {
  //   auth.onAuthStateChanged((u) => {
  //     if (u) setUser(u);
  //   });
  // }, []);

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      {/* <Router> */}
      <div className="view-stacked">
        <Navbar />
        <Content />
      </div>
      {/* </Router> */}
    </Provider>
  );
};

export default App;
