import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DarkMode from "../../reaction-time/components/DarkMode";
import logo from "../../nectar_icononly.svg";
import { login, logout } from "../../data/actions/auth";

const Navbar = ({ login, logout, auth: { user } }) => {
  const [show, setShow] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-brand text-secondary">
        <img
          src={logo}
          alt="Nectar Logo"
          // style={{ height: "3rem", width: "auto" }}
        />
        <span>Nectar</span>
      </div>
      <div
        className={`nav-collapse-toggler ${show ? "close" : ""}`}
        onClick={() => setShow(!show)}
      >
        <div className="btn-line"></div>
        <div className="btn-line"></div>
        <div className="btn-line"></div>
      </div>
      <div className={`nav-collapse ${show ? "show" : ""}`}>
        <ul className="nav-links">
          {user ? (
            <li className="nav-link" onClick={logout}>
              Log Out
            </li>
          ) : (
            <li className="nav-link" onClick={login}>
              Log In
            </li>
          )}
          <DarkMode />
        </ul>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login, logout })(Navbar);
